import React from 'react'
import { styled } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { ServerlessLoader } from './ServerlessLoader'
import { Typography } from '@mui/material'

export const LoadingPage = ({ message }) => {
  return (
    <ContainerPage>
      <Content
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 0.8, opacity: 1 }}
        transition={{ duration: 0.35 }}
      >
        <ServerlessLoader />
        {message && <Typography color="text.secondary">{message}</Typography>}
      </Content>
    </ContainerPage>
  )
}

export default LoadingPage

/**
 * Styles
 */
const ContainerPage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  width: '100%',
  padding: '0',
  overflow: 'hidden',
  background: theme.palette.secondary.main,
  borderRadius: '6px',
}))

const Content = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-4%',
  padding: '0',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
}))
