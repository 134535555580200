export const swrConfig = {
  onErrorRetry: (err, key, config, revalidate, opts) => {
    const statusCode = err?.response?.status

    /**
     * if it's hidden, stop
     * it will auto revalidate when focus
     */
    if (!config.isDocumentVisible()) {
      return
    }

    /**
     * Exhausted retry count
     */
    if (typeof config.errorRetryCount === 'number' && opts.retryCount > config.errorRetryCount) {
      return
    }

    /**
     * Don't retry on HTTP 4xx errors
     */
    if (statusCode >= 400 && statusCode < 500) {
      return
    }

    /**
     * 504: Request timed out. Client should retry (with exponential backoff) at regular rate.
     * 502: Connection error. Client should retry at reduced rate and/or higher exponential backoff.
     * 5xx: Same as 502.
     */
    let multiplier = 1.5

    if (statusCode === 504) {
      multiplier = 1
    }

    /**
     * Exponential backoff
     * By default try 5 times
     */
    const count = Math.min(opts.retryCount || 0, 5)
    const timeout =
      ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval * multiplier
    setTimeout(revalidate, timeout, opts)
  },
}
