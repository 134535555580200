import React from 'react'
import { Box, Typography } from '@mui/material'
import { IconServerlessFramework } from 'app/icons/IconServerlessFramework'

/**
 * Simple bolt + label content to show as an empty state for
 * various pages
 */
export const EmptyState = ({ label, size = 'medium', sx = {}, ...rest }) => {
  const sizeProps = {
    icon: size === 'small' ? 70 : 100,
    text: size === 'small' ? 'h4' : 'h2',
  }
  return (
    <Box
      // {...animateProps()}
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        color: 'grey.light',
        gap: '20px',
        ...rest,
      }}
    >
      <IconServerlessFramework size={sizeProps.icon} />

      {label && <Typography variant={sizeProps.text}>{label}</Typography>}
    </Box>
  )
}
