/**
 * Utils: Serverless Platform SDK
 */

import { ServerlessSDK } from '@serverless/platform-client'
import config from 'config'
import { session } from 'auth/session'

const idToken = session.get('authIdToken')

export const serverless = new ServerlessSDK({
  platformStage: config.platform.stage,
  accessKey: idToken,
})
