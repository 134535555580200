import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Box, Tooltip, Fade } from '@mui/material'
import { session } from 'auth/session'
import { styled, useTheme } from '@mui/styles'
import { IconServerlessFramework } from '../icons/IconServerlessFramework'
import {
  BarChartBigIcon,
  ContactIcon,
  CpuIcon,
  CreditCardIcon,
  LogOutIcon,
  PocketKnifeIcon,
  ShieldCheckIcon,
  SunMoonIcon,
} from 'lucide-react'
import SearchDialog from 'search/SearchDialog'
import { useHotkeys } from 'react-hotkeys-hook'

const SidebarMenuItem = ({ title, icon, active, to, onClick }) => {
  const Icon = icon
  return (
    <Tooltip
      title={title}
      placement="right"
      key={`sidebar-links-0`}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
    >
      <Box width="100%">
        <SidebarLink className={active ? 'active' : ''} to={to} onClick={onClick}>
          <SidebarLinkInner>
            <Icon size={24} />
          </SidebarLinkInner>
        </SidebarLink>
      </Box>
    </Tooltip>
  )
}

export const Sidebar = () => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const isLoggedIn = session.isLoggedIn()
  const [searchOpen, setSearchOpen] = useState(false)

  useHotkeys('meta+shift+f', () => {
    setSearchOpen(true)
  })

  const section = pathname.split('/')[1] || 'subscriptions'

  if (!isLoggedIn) {
    /**
     * Return null if not logged in or account set up
     */
    return null
  }

  const nextMode =
    theme.palette.savedMode === 'dark'
      ? 'light'
      : theme.palette.savedMode === 'light'
      ? 'system'
      : 'dark'

  const signOut = () => {
    session.destroy()
    window.location.href = '/'
  }

  return (
    <>
      <SearchDialog open={searchOpen} onClose={() => setSearchOpen(false)} />
      <SidebarWrapper>
        <ConsoleLink to={`/`}>
          <IconServerlessFramework size={40} />
        </ConsoleLink>
        <SidebarMenuItem
          title="Users & Orgs (cmd + shift + f)"
          icon={ContactIcon}
          active={['search', 'contacts', 'companies', 'orgs'].includes(section)}
          onClick={() => setSearchOpen(true)}
        />
        <SidebarMenuItem
          title="Subscriptions"
          icon={CreditCardIcon}
          active={section === 'subscriptions'}
          to="/subscriptions?filterStatus=active"
        />
        <SidebarMenuItem
          title="CI/CD Memory Config"
          icon={CpuIcon}
          active={section === 'deployment-settings'}
          to="/deployment-settings"
        />
        <SidebarMenuItem
          title="Tools"
          icon={PocketKnifeIcon}
          active={section === 'tools'}
          to="/tools"
        />
        <SidebarMenuItem
          title="Reports"
          icon={BarChartBigIcon}
          active={section === 'reports'}
          to="/reports"
        />

        {/* Logged in content */}
        {isLoggedIn && (
          <SidebarBottomSection>
            <SidebarMenuItem
              title="Superadmins"
              icon={ShieldCheckIcon}
              active={section === 'superadmins'}
              to="/superadmins"
            />
            <SidebarMenuItem
              title={`Mode: ${theme.palette.savedMode}`}
              icon={SunMoonIcon}
              onClick={() => theme.palette.setSavedMode(nextMode)}
            />
            <SidebarMenuItem title="Logout" icon={LogOutIcon} onClick={signOut} />
          </SidebarBottomSection>
        )}
      </SidebarWrapper>
    </>
  )
}

export const sidebarLinkStyles = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '100px',
  width: '100%',
  padding: '25px 0',
  transition: 'all 0.2s ease',
  color: theme.palette.grey.light,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey.dark,
    color: theme.palette.primary.main,
  },
  '&.active': {
    color: theme.palette.primary.main,
  },
})

const ConsoleNavLink = ({ children, ...props }) => <Link {...props}>{children}</Link>

const SidebarLink = styled(ConsoleNavLink)(({ theme }) => ({
  ...sidebarLinkStyles(theme),
}))

const SidebarLinkInner = styled('div')(({ theme }) => ({
  display: 'flex',
}))

const SidebarWrapper = styled('div')(({ theme, ...props }) => {
  const topPadding = 18
  const bottomPadding = 40 // Don't put content at bottom of screen or it triggers users' OSX dock
  return {
    backgroundColor: theme.palette.secondary.main,
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: `100vh`,
    width: '80px',
    minWidth: '80px',
    padding: `${topPadding}px 0 ${bottomPadding}px 0`,
    transition: 'all 0.2s ease',
    borderRight: `1px solid ${theme.palette.border.main}`,
    boxSizing: 'border-box',
  }
})

export const ConsoleLink = styled(ConsoleNavLink)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  height: 'auto',
  color: theme.palette.primary.main,
  marginBottom: '20px',
  padding: '2px 0 10px 0px',
  opacity: 1,
  transition: 'all 0.2s ease',
  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(1.22)',
    opacity: '1',
  },
  '&:active': {
    cursor: 'pointer',
    transform: 'scale(1.1)',
    opacity: '1',
  },
}))

const SidebarBottomSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'auto',
  width: '100%',
  padding: '0',
  margin: 'auto 0 0 0',
}))
