import { Box } from '@mui/material'
import { Sidebar } from './app/components/Sidebar'
import { Outlet } from 'react-router-dom'
import { Suspense } from 'react'
import LoadingPage from './common/components/LoadingPage'

export const Layout = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '80px auto',
        gridTemplateRows: '1fr',
        height: '100vh',
      }}
    >
      <Sidebar />
      <Box
        sx={{
          height: '100%',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Suspense fallback={<LoadingPage />}>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  )
}
