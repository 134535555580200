import React from 'react'
import { makeStyles } from '@mui/styles'
import { IconServerlessFramework } from 'app/icons/IconServerlessFramework'
import { Box } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    position: 'relative',
    width: 150,
    height: 165,
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'scale(0.5)',
  },

  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 160,
  },

  outerCircle: {
    backgroundColor: 'transparent',
    border: `4px solid ${theme.palette.grey.light}`,
    opacity: '0.9',
    borderRight: '5px solid transparent',
    borderLeft: '5px solid transparent',
    borderRadius: 100,
    width: 144,
    height: 144,
    margin: '0 auto',
    animation: '$spinPulse 3s infinite ease-in-out',
  },

  innerCircle: {
    backgroundColor: 'transparent',
    border: `3px solid ${theme.palette.primary.main}`,
    opacity: '0.9',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderRadius: 100,
    top: -133,
    width: 122,
    height: 122,
    margin: '0 auto',
    position: 'relative',
    animation: '$spinOffPulse 1s infinite linear',
  },

  '@keyframes spinPulse': {
    '0%': {
      transform: 'rotate(160deg)',
      opacity: 0,
    },
    '50%': {
      transform: 'rotate(145deg)',
      opacity: 1,
    },
    '100%': {
      transform: 'rotate(-320deg)',
      opacity: 0,
    },
  },

  '@keyframes spinOffPulse': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

export const ServerlessLoader = ({
  scale = 1,
  width = 150,
  height = 165,
  showLogo = true,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div
      {...props}
      className={`${classes.loaderContainer} ${props.className || ''}`}
      style={{
        width,
        height,
        transform: `scale(${scale})`,
        userSelect: 'none',
        pointerEvents: 'none',
        ...props.style,
      }}
    >
      {showLogo && (
        <Box
          sx={{
            position: 'relative',
            top: '-10px',
            left: '-5px',
          }}
        >
          <IconServerlessFramework size={70} />
        </Box>
      )}

      <div
        className={classes.loader}
        style={showLogo ? { position: 'absolute' } : { position: 'initial' }}
      >
        <div className={classes.outerCircle}></div>
        <div className={classes.innerCircle}></div>
      </div>
    </div>
  )
}
