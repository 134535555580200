import { useCallback, useState, createElement } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

// Client
import CoreSDKAdmin from '@serverlessinc/core-sdk-admin'
import config from 'config'
import { session } from 'auth/session'
import { apiClient } from 'common/api'

// Icons
import { BuildingIcon, ContactIcon, CreditCardIcon, SearchIcon, XCircleIcon } from 'lucide-react'

// Components
import { BorderLinearProgress } from 'common/components/PageLayout'

const CompactDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    margin: 0,
  },
  '& .MuiPaper-root': {
    padding: 0,
  },
  '& .MuiDialogTitle-root': {
    padding: 0,
    borderBottom: '1px solid #e0e0e0',
  },
}))

const groups = {
  org: 'Orgs',
  subscription: 'Subscriptions',
  contact: 'Contacts',
  company: 'Companies',
  awsAccount: 'AWS Accounts',
  orgsByEmail: 'Org Membership'
}

const SearchDialog = ({ open, onClose }) => {
  const [term, setTerm] = useState('')
  const [loading, setLoading] = useState(false)

  const [contacts, setContacts] = useState([])
  const [companies, setCompanies] = useState([])
  const [orgs, setOrgs] = useState([])
  const [orgsByEmails, setOrgsByEmails] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const [awsAccounts, setAwsAccounts] = useState([])

  const results = {
    company: companies,
    contact: contacts,
    org: orgs,
    subscription: subscriptions,
    awsAccount: awsAccounts,
    orgsByEmail: orgsByEmails,
  }

  const navigate = useNavigate()

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        search(term)
      }
    },
    [term]
  )

  const clickResult = useCallback((link) => {
    onClose()
    navigate(link)
  })

  const search = useCallback(async (searchTerm) => {
    setLoading(true)

    const idToken = session.get('authIdToken')
    const client = new CoreSDKAdmin({
      authToken: idToken,
      platformStage: config.platform.stage,
    })
    try {
      const queries = []

      const searchEntities = async () => {
        let searchResults = []
        try {
          searchResults = await client.admin.searchEntities({ queryString: searchTerm })
        } catch (err) {
          searchResults = []
        }

        setContacts(
          searchResults
            .filter((e) => e.type === 'contact')
            .map((entity) => ({
              link: `/contacts/${entity.email}`,
              icon: ContactIcon,
              name: entity.fullName || entity.email,
              description: entity.email,
            }))
        )

        setCompanies(
          searchResults
            .filter((e) => e.type === 'company')
            .map((entity) => ({
              link: `/companies/${entity.domain}`,
              icon: BuildingIcon,
              name: entity.domain,
              description: entity.domain,
            }))
        )
      }

      const searchSubscription = async () => {
        try {
          const result = await client.billingAdmin.getSubscription({ subscriptionId: searchTerm })
          setSubscriptions([
            {
              link: `/orgs/${result.orgId}?subscriptionId=${result.subscriptionId}`,
              icon: CreditCardIcon,
              name: result.subscriptionId,
              description: result.orgId,
            },
          ])
        } catch {
          setSubscriptions([])
        }
      }

      const searchOrgsByEmail = async () => {
        try {
          const orgs = await apiClient({
            url: 'core/tenants',
            params: {email: searchTerm},
          })
          setOrgsByEmails(orgs.map(org=>(
            {
              link: `/orgs/${org.orgUid}`,
              icon: BuildingIcon,
              name: org.orgName,
              description: org.orgUid,
            }
          )))
        } catch (ex) {
          setOrgsByEmails([])
        }
      }

      const searchOrgs = async () => {
        const queries = []
        queries.push(client.orgs.getByName({ orgName: searchTerm }))
        queries.push(client.orgs.getByUid({ orgUid: searchTerm }))

        try {
          const result = await Promise.any(queries)
          if (result) {
            setOrgs([
              {
                link: `/orgs/${result.orgUid}`,
                icon: BuildingIcon,
                name: result.orgName,
                description: result.orgUid,
              },
            ])
          }
        } catch {
          setOrgs([])
        }
      }

      const searchAwsAccounts = async () => {
        try {
          const result = await client.billingAdmin.getSubscriptionByProviderCustomerAccountId({providerCustomerAccountId: searchTerm})
          setAwsAccounts([
            {
              link: `/orgs/${result.orgId}?subscriptionId=${result.subscriptionId}`,
              icon: CreditCardIcon,
              name: result.subscriptionId,
              description: result.orgId,
            },
          ])
        } catch (er) {
          setAwsAccounts([])
        }
      }

      queries.push(searchEntities())
      queries.push(searchOrgs())
      queries.push(searchSubscription())
      queries.push(searchAwsAccounts())
      queries.push(searchOrgsByEmail())

      await Promise.all(queries)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  })

  return (
    <CompactDialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        <TextField
          onChange={(event) => {
            setTerm(event.target.value)
          }}
          sx={{ width: '700px', p: 1 }}
          autoFocus={true}
          fullWidth={true}
          value={term}
          onKeyDown={handleKeyDown}
          variant="standard"
          placeholder="Search orgs, subscriptions, companies, contacts, etc."
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon size={18} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClose}>
                  <XCircleIcon size={18} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogTitle>
      {loading && <BorderLinearProgress />}
      {results && (
        <DialogContent>
          <List sx={{ width: '100%' }}>
            {Object.keys(groups).map((key, i) => {
              const groupResults = results[key].slice(0, 3)
              return (
                groupResults.length > 0 && (
                  <Box key={i}>
                    <ListSubheader>{groups[key]}</ListSubheader>
                    {groupResults.map((result, i) => {
                      return (
                        <ListItem
                          sx={{ padding: 0 }}
                          onClick={() => clickResult(result.link)}
                          key={i}
                        >
                          <ListItemButton>
                            <ListItemIcon>{createElement(result.icon, { size: 16 })}</ListItemIcon>
                            <ListItemText primary={result.name} secondary={result.description} />
                          </ListItemButton>
                        </ListItem>
                      )
                    })}
                  </Box>
                )
              )
            })}
          </List>
        </DialogContent>
      )}
    </CompactDialog>
  )
}

export default SearchDialog
