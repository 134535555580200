import React, { Suspense, lazy } from 'react'
import { render } from 'react-dom'
import { PrivateRoute } from 'app/components/PrivateRoute'

import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { QueryParamProvider } from 'use-query-params'
import { Theme } from './theme/theme'
import { Layout } from './Layout'
import { swrConfig } from './SwrConfig'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { parse, stringify } from 'query-string'
import LoadingPage from './common/components/LoadingPage'
import { ErrorBoundary } from './common/components/ErrorBoundary'
import { SnackbarProvider } from './app/context/SnackbarContext'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import 'app/fonts/index.css'

/**
 * Pages
 */
const NotFoundPage = lazy(() => import('./app/components/NotFoundPage'))
const SubscriptionListPage = lazy(() => import('./subscriptions/SubscriptionListPage'))
const SubscriptionCreatePage = lazy(() => import('./subscriptions/SubscriptionCreatePage'))
const SubscriptionEditPage = lazy(() => import('./subscriptions/SubscriptionEditPage'))
const SuperAdminListPage = lazy(() => import('./superadmins/SuperAdminListPage'))
const OrgDetailsPage = lazy(() => import('./orgs/OrgDetailsPage'))
const CustomerContactDetailsPage = lazy(() => import('./customers/CustomerContactDetailsPage'))
const CustomerCompanyDetailsPage = lazy(() => import('./customers/CustomerCompanyDetailsPage'))
const ToolsPage = lazy(() => import('./tools/ToolsPage'))
const ReportsPage = lazy(() => import('./reports/ReportsPage'))
const DeploymentSettingsPage = lazy(() =>
  import('./deployment-settings/DeploymentSettingsListPage')
)
const DeploymentSettingsCreatePage = lazy(() =>
  import('./deployment-settings/DeploymentSettingsCreatePage')
)

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />} errorElement={<ErrorBoundary />}>
        <Route path="" errorElement={<ErrorBoundary />} element={<Layout />}>
          <Route path="" element={<Navigate to="/subscriptions?filterStatus=active" />} />
          <Route path="/subscriptions" element={<SubscriptionListPage />} />
          <Route path="/subscriptions/new" element={<SubscriptionCreatePage />} />
          <Route path="/subscriptions/:subscriptionId/edit" element={<SubscriptionEditPage />} />
          <Route path="/superadmins" element={<SuperAdminListPage />} />
          <Route path="/orgs/:orgId" element={<OrgDetailsPage />} />
          <Route path="/contacts/:email" element={<CustomerContactDetailsPage />} />
          <Route path="/companies/:domain" element={<CustomerCompanyDetailsPage />} />
          <Route path="/deployment-settings" element={<DeploymentSettingsPage />} />
          <Route path="/deployment-settings/new" element={<DeploymentSettingsCreatePage />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    </Routes>
  )
}
/**
 * Wraps the app with necessary providers (theme, router, etc.)
 */
const App = () => {
  return (
    <BrowserRouter>
      <Theme>
        <SWRConfig value={swrConfig}>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <SnackbarProvider>
                <Suspense fallback={<LoadingPage />}>
                  <DashboardRoutes />
                </Suspense>
              </SnackbarProvider>
            </LocalizationProvider>
          </QueryParamProvider>
        </SWRConfig>
      </Theme>
    </BrowserRouter>
  )
}

/**
 * Render the app!
 */
render(<App />, document.getElementById('root'))

serviceWorkerRegistration.unregister()
