/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
/**
 * Utils
 */
import { session } from 'auth/session'

/**
 * Pages
 */
import { useInterval, useMount } from 'react-use'
import Authentication from 'auth/components/Authentication'
import PageVisibility from 'react-page-visibility'

/**
 * If you're within 5 minutes of the token expiring, consider it invalid.
 * The idea here is to catch a soon-expiring session, so users don't get
 * failed requests as they navigate around before one of these ticks happens.
 */
const timeBuffer = 300000

/**
 * Ensures that the user is logged in to see this route. If not,
 * the user is shown the auth flow
 */
export const PrivateRoute = () => {
  const checkSession = () => {
    if (session.isLoggedIn()) {
      const grantedTimeMs = session.get('authTimeGranted')
      /**
       * The expiration time is supposed to come from the token response
       * of POST /tokens, which is savd here: session.get('authExpires')
       * but in my testing, the expiration is always 10 hours
       */
      const expiresInMs = 864000 * 1000 // 10 hours

      if (Date.now() > expiresInMs + grantedTimeMs + timeBuffer) {
        session.destroy()

        window.location.href = '/'
      }
    }
  }

  /**
   * Check on mount and perciodically to see if the session is valid
   */
  useMount(checkSession)
  useInterval(checkSession, session.isLoggedIn() ? 30000 : null)

  if (!session.isLoggedIn() || !session.isAccountSetup()) {
    return <Authentication />
  }

  /**
   * You're logged in and setup, just use the component specified for the route
   */
  if (session.isLoggedIn() && session.isAccountSetup()) {
    return (
      <PageVisibility onChange={(isVisible) => isVisible && session.isLoggedIn() && checkSession()}>
        <Outlet />
      </PageVisibility>
    )
  }

  return <Navigate to={`/`} />
}
