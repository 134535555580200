import { Alert, Snackbar } from '@mui/material'
import { createContext, useContext, useState } from 'react'

export const SnackbarTypes = {
  error: 'ERROR',
  info: 'INFO',
}
export const SnackbarContext = createContext()

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: 'Hi 👋',
    duration: 6000,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    type: SnackbarTypes.info,
  })

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      visible: false,
    })
  }

  return (
    <SnackbarContext.Provider
      value={{
        setSnackbar: (data) =>
          setSnackbar({
            ...snackbar,
            visible: true,
            ...data,
          }),
      }}
    >
      {children}

      <Snackbar
        open={snackbar.visible && snackbar.type === SnackbarTypes.info}
        autoHideDuration={snackbar.duration}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={snackbar.anchorOrigin}
      />

      <Snackbar
        open={snackbar.visible && snackbar.type === SnackbarTypes.error}
        autoHideDuration={snackbar.duration}
        onClose={handleSnackbarClose}
        anchorOrigin={snackbar.anchorOrigin}
      >
        <Alert
          sx={{ borderRadius: '6px' }}
          severity="error"
          // variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => useContext(SnackbarContext).setSnackbar
