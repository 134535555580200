import axios from 'axios'
import { useRef } from 'react'
import useSWR from 'swr'

import config from 'config'
import { session } from 'auth/session'

const idToken = session.get('authIdToken')

export const apiClient = axios.create({
  baseURL: config.platform.apiUrl,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${idToken}`,
  },
})

export const coreApiClient = axios.create({
  baseURL: config.platform.coreApiUrl,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${idToken}`,
    'sls-token-type': 'dashboardToken',
  },
})

// Add a response interceptor
apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx
    return response?.data
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
// Add a response interceptor
coreApiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx
    return response?.data
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

/**
 * Return previous results while revalidating
 */
export function useStickySWR(...args) {
  const val = useRef()

  const { data, isValidating, error, ...rest } = useSWR(...args)

  if (data !== undefined) {
    val.current = data
  }

  return {
    ...rest,
    isValidating,
    error,
    data: val.current,
  }
}
