import React, { useState } from 'react'

import { useLocation } from 'react-router-dom'
import { TextField, Container, Box, Button, Divider } from '@mui/material'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import { useMount } from 'react-use'
import queryString from 'query-string'
import { ServerlessLoader } from 'common/components/ServerlessLoader'
import { authSocial, authViaForm, authCallback } from 'auth/utils'

import { session } from 'auth/session'
import { apiClient } from 'common/api'
import { serverless } from 'common/sdk'
import { styled } from '@mui/material'
import { GitHub, Google } from '@mui/icons-material'
import { IconServerlessFramework } from 'app/icons/IconServerlessFramework'

/**
 * Authentication
 */
export const Authentication = () => {
  const { pathname, search } = useLocation()

  /**
   * Query parameters
   */
  const [query, setQuery] = useQueryParams({
    /**
     * This query parameter is the Auth0 callback flow 'code'.
     * If provided, this is the callback flow from Auth0.
     */
    code: StringParam,

    /**
     * The type of 'client' that is logging in (e.g. 'browser', 'cli')
     */
    client: withDefault(StringParam, 'browser'),

    /**
     * Error coming back from auth0 if 3rd party cookies are disabled
     */
    error_description: StringParam,

    /**
     * This is for non-browser clients trying to log in (e.g cli or electron app)
     */
    transactionId: StringParam,

    /**
     * This is for the referring app template (e.g. express, website)
     */
    template: StringParam,

    /**
     * When creating an app, this parameter specifies which template or component
     * should be selected. We'll use it to display something fun on the login page.
     */
    package: StringParam,

    /**
     * (Optional)
     * Can be either 'github' or 'google-oauth2'. If specified, then the social
     * auth button is immediately invoked.
     */
    socialAuthType: StringParam,

    /**
     * UTM tracking parameters
     */
    utm_source: StringParam,
    utm_medium: StringParam,
    utm_campaign: StringParam,
    utm_content: StringParam,
    utm_term: StringParam,
  })

  /**
   * Set initial state to loading if a code is in the URL, but not for the join flow
   */
  const [loading, setLoading] = useState(query.code)
  const [error, setError] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useMount(() => {
    async function run() {
      /**
       * If no 'code', the user has landed on the register/login page...
       */
      if (!query.code) {
        session.set('authClient', query.client)
        session.set('authClientTransactionId', query.transactionId)

        /**
         * Save URL to redirect to after authentication in the session.
         * Also save the query parameters... these are trickier because
         * we need to distinguish app query params vs. auth0 callback
         * and marketing ones.
         */
        if (pathname !== '/') {
          session.set('authRedirectPath', pathname)
        }

        /**
         * Set UTM tracking parameters
         */
        session.set('referrerSource', query.utm_source)
        session.set('referrerMedium', query.utm_medium)
        session.set('referrerCampaign', query.utm_campaign)
        session.set('referrerContent', query.utm_content)
        session.set('referrerTerm', query.utm_term)

        /**
         * Track Package (if exists) so we know what originally brought this user to us
         */

        session.set('referrerPackage', query.package)
      }

      /**
       * Save query parameters
       */
      if (!query.code) {
        session.set('authRedirectQuery', search)
      }

      /**
       * If 'code' query parameter exists, the Authentication page is handling a callback from Auth0
       */
      if (query.code) {
        setLoading(true)

        /**
         *  Exchange auth0 callback "code" for "tokens"
         */
        try {
          const { access_token, refresh_token, id_token, expires_in } = await authCallback(
            query.code,
            window.location.origin
          )

          /**
           * Clear out query parameters that we don't want to carry through to the
           * logged in experience
           */
          setQuery(
            {
              code: undefined,
              state: undefined,
              transactionId: undefined,
              utm_source: undefined,
              utm_medium: undefined,
              utm_content: undefined,
              utm_term: undefined,
              view: undefined,
            },
            'replaceIn'
          )

          let me = null

          /**
           * Update Serverless SDK w/ authorized token
           */
          serverless.config({ accessKey: id_token })

          /**
           * Persist the current session to localStorage
           */
          session.login({
            authAccessToken: access_token,
            authRefreshToken: refresh_token,
            authIdToken: id_token,
            /**
             * Nubmer of seconds token is good for
             */
            authExpires: expires_in,
            /**
             * Current UNIX timestamp in milliseconds
             */
            authTimeGranted: Date.now(),
          })

          /**
           * Try to fetch your own information
           *
           * Note: if you are registering as a new user, then this request will 404
           */
          try {
            me = await apiClient({
              url: 'core/me',
              headers: {
                Authorization: `Bearer ${id_token}`,
              },
            })

            /**
             * Save the current user to the session
             */
            session.set('user', me)
          } catch (error) {
            /**
             * We got a 404, we need the user to specify an 'username' to finish account setup
             */
            if (error.message?.includes('404')) {
              setLoading(false)
            }

            /**
             * We can't continue, since the user doesn't have an org setup yet
             */
            return
          }

          const path = session.get('authRedirectPath') || `/`

          /**
           * Piece together a query string if it was present before the auth. Let's
           * scrub unwanted/marketing params, too
           */
          let q = queryString.parse(session.get('authRedirectQuery'))

          delete q.code
          delete q.state
          delete q.utm_source
          delete q.utm_medium
          delete q.utm_campaign
          delete q.utm_content
          delete q.utm_term
          delete q.view
          delete q.socialAuthType

          const stringQuery = queryString.stringify(q)
          q = stringQuery ? `?${stringQuery}` : ''

          /**
           * Hard refresh to show the app
           */

          window.location.href = `${path}${q}`
        } catch (e) {
          setQuery({ code: undefined, state: undefined }, 'replaceIn')
          setLoading(false)
        }
      }
    }

    run()
  })

  /**
   * Authenticate via the form.  Handles Register & Sign-in
   */
  const handleAuthFormSubmit = async (event) => {
    event.preventDefault()

    setError(null)
    setLoading(true)

    try {
      await authViaForm('signIn', email, password)
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Authenticate via a social provider
   * @param {*} method
   */
  const authViaSocial = async (method) => {
    setLoading(true)

    try {
      await authSocial(method)
    } catch (error) {
      setLoading(false)
      setError(error.message || 'Sorry, an unknown error occurred.  Please try again later.')
    }
  }

  /**
   * If the social auth query param is set, then automatically use that mechanism
   */
  useMount(() => {
    if (query.socialAuthType) {
      authViaSocial(query.socialAuthType)
    }
  }, [query.socialAuthType])

  const content = (() => {
    return (
      <Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            mb: 4,
            gap: '10px',
          }}
        >
          <GithubOAuthButton onClick={() => authViaSocial('github')}>
            Continue with GitHub
          </GithubOAuthButton>
          <GoogleOAuthButton onClick={() => authViaSocial('google-oauth2')}>
            Continue with Google
          </GoogleOAuthButton>
        </Box>

        <Divider color="text.secondary">or</Divider>
        <Form onSubmit={handleAuthFormSubmit}>
          <Label>Email</Label>
          <TextField
            name="email"
            autoFocus
            fullWidth
            value={email}
            color={'primary'}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Label>Password</Label>
          <TextField
            name="password"
            type="password"
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <SubmitButton color="primary" variant="contained" type="submit">
            Login
          </SubmitButton>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      </Box>
    )
  })()
  return (
    <Main>
      {loading ? (
        <ServerlessLoader style={{ margin: '0 auto' }} scale={0.8} />
      ) : (
        <>
          <IconServerlessFramework size={70} />
          <Content>{content}</Content>
        </>
      )}
    </Main>
  )
}

const Main = styled('main')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '40px',
  margin: 'auto',
}))

const Content = styled((props) => <Container maxWidth="xs" {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0 auto',
  width: 'auto',
  minWidth: '30vw',
  maxWidth: '350vw',
  // Addresses vertical alignment on higher res screens
}))

const Form = styled('form')({
  '& > label:first-child': {
    marginTop: 0,
  },
})

const Label = styled('label')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  display: 'block',
  lineHeight: 2,
  marginTop: theme.spacing(4),
}))

const ErrorMessage = styled('p')(({ theme }) => ({
  textAlign: 'center',
  color: '#fd5750',
  fontWeight: 'bold',
  fontSize: theme.typography.fontSize,
}))

const SubmitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(4),
}))

const GoogleOAuthButton = (props) => (
  <Button sx={{ width: '100%' }} {...props}>
    <Google sx={{ margin: '0 12px 0 0' }} /> Google
  </Button>
)

const GithubOAuthButton = (props) => (
  <Button sx={{ width: '100%' }} {...props}>
    <GitHub sx={{ margin: '0 12px 0 0' }} /> Github
  </Button>
)
export default Authentication
