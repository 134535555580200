import config from 'config'

const LOCAL_STORAGE_KEY = config.platform.stage === 'dev' ? 'serverless-dev' : 'serverless'

/**
 * Utilities: Session
 * This exports a session class which includes all methods necessary for handling browser sessions,
 * ensuring authentication, logging users out, and more.
 */

/**
 * Serverless Dashboard User Session
 */
class Session {
  constructor() {
    /**
     * To reduce JSON.parse calls, store/read .get values here
     */
    this.cache = {}
  }

  /**
   * Signs the user in by saving a required set of properties to the session.
   * By validating the required properties are included in the session, sessions
   * are more consistent and predicatable.
   * @param {object} data Required session properties.  Review the "set" method for all descriptions.
   */
  login(data = {}) {
    // Validate required data has been submitted.
    const required = ['authAccessToken', 'authIdToken', 'authRefreshToken', 'authExpires']
    required.forEach((key) => {
      if (!data[key]) {
        throw new Error(
          `Unable to log user in and create a browser session due to missing required session data: ${key}.`
        )
      }
    })

    // Save data to session
    for (const key in data) {
      this.set(key, data[key])
    }

    return this.getAll
  }

  /**
   * Returns a boolean to indicate whether the user is authenticated
   */
  isLoggedIn() {
    const authAccessToken = this.get('authAccessToken')
    const authIdToken = this.get('authIdToken')

    if (!authAccessToken || !authIdToken) {
      return false
    }

    return true
  }

  /**
   * If you have user information, and you are logged in, then you're good to go
   */
  isAccountSetup() {
    const user = this.get('user')

    if (this.isLoggedIn() && user) {
      return true
    }

    return false
  }

  /**
   * Sign the user out by deleting their browser session and doing a hard reload of the page,
   * which should direct them to the auth view.
   * @param {boolean} reload If provided, performs a hard redirect to the Auth screen.
   */
  logOut(reload = false) {
    this.destroy()

    if (reload) {
      window.location.replace(window.location.origin)
    }
  }

  /**
   * Get a property from the user session
   */
  get(key) {
    if (this.cache[key]) {
      return this.cache[key]
    }

    let sessionData = localStorage.getItem(LOCAL_STORAGE_KEY) || {}
    sessionData = typeof sessionData === 'string' ? JSON.parse(sessionData) : sessionData
    const value = sessionData[key] || null

    this.cache[key] = value

    return value
  }

  /**
   * Get all data from the user session
   */
  getAll() {
    let sessionData = localStorage.getItem(LOCAL_STORAGE_KEY) || {}
    return typeof sessionData === 'string' ? JSON.parse(sessionData) : sessionData
  }

  /**
   * Set data in the session via a key and value
   * @param {string} authClient Where is the user logging in from?  'browser', 'cli'.
   * @param {string} authAccessToken The Auth0 Access Token.
   * @param {string} authIdToken The Auth0 ID Token.
   * @param {string} authRefreshToken The Auth0 Refresh Token.
   * @param {string} authExpires The Auth0 ID Token expiration date.
   * @param {string} authJoinOrganizationInvitationToken Code from getting an team invitation
   * @param {object} user The user object returned from /core/me
   * @param {string} selectedOrgName When you switch tenants, this property gets set
   * @param {string} authClientTransactionId If the user is logging in from the 'cli', this transaction id is needed to send a callback to the right CLI session.
   * @param {string} authRedirectPath If the user tried to access a specific page before authentication, it's saved here to redirect to.
   * @param {string} authTimeGranted When the token was generated
   * @param {string} referrerTemplateName If the user logged in by clicking on a template (e.g. "node-rest-api"), save it in the session to use in tracking.
   */
  set(key, val) {
    const allowedKeys = [
      'authClient',
      'authAccessToken',
      'authIdToken',
      'authRefreshToken',
      'authExpires',
      'authClientTransactionId',
      'authRedirectPath',
      'authRedirectQuery',
      'authTimeGranted',
      'authJoinOrganizationInvitationToken',
      'user',
      'selectedOrgName',
      'referrerPackage',
      'referrerClient',
      'referrerSource',
      'referrerMedium',
      'referrerCampaign',
      'referrerContent',
      'referrerTerm',
    ]

    if (allowedKeys.indexOf(key) < 0) {
      throw new Error(
        `Invalid session property: ${key}.  Please don't pollute user sessions.  Every User has a "Meta" object where custom data can be stored in the database.  Use that instead.`
      )
    }

    const sessionData = this.getAll()
    sessionData[key] = val

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(sessionData))

    return sessionData
  }

  /**
   * Destroy browser session
   */
  destroy() {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
    this.cache = {}
  }
}

export const session = new Session()
