/**
 * Auth: Utils
 * Auth0-related utility functions
 */

import Auth0 from 'auth0-js'
import config from 'config'
import { apiClient } from 'common/api'
import isEmail from 'validator/lib/isEmail'

/**
 * Initialize Auth0
 */
const auth0 = new Auth0.WebAuth({
  domain: config.auth0.domain,
  clientID: config.auth0.clientId,
  redirectUri: `${window.location.origin}/`,
  responseType: 'code',
})

/**
 * Sign up via Auth0
 *
 * @param {*} email
 * @param {*} password
 */
export const authSignUp = (email, password) =>
  new Promise((resolve, reject) => {
    auth0.signup(
      {
        email,
        password,
        connection: 'Username-Password-Authentication',
        audience: config.auth0.audience,
        scope: config.auth0.scope,
      },
      (error, result) => {
        if (error) {
          return reject(error)
        }

        return resolve(result)
      }
    )
  })

/**
 * Log in via Auth0
 *
 * @param {*} email
 * @param {*} password
 */
export const authLogIn = (email, password) =>
  new Promise((resolve, reject) => {
    auth0.login(
      {
        email,
        password,
        audience: config.auth0.audience,
        scope: config.auth0.scope,
      },
      (error, result) => {
        if (error) {
          return reject(error)
        }

        return resolve(result)
      }
    )
  })

/**
 * Sign up or log in via third party
 *
 * @param {*} method must be github or goole
 */
export const authSocial = (method) => {
  if (!method) {
    throw new Error(`Method must be 'github' or 'google'`)
  }

  return new Promise((resolve, reject) => {
    auth0.authorize(
      {
        audience: config.auth0.audience,
        scope: config.auth0.scope,
        connection: method,
      },
      (error, result) => {
        if (error) {
          return reject(error)
        }

        return resolve(result)
      }
    )
  })
}

/**
 * Authenticate via the form.  Handles Register & Sign-in
 */
export const authViaForm = async (authType = 'signIn', email, password, confirmPassword) => {
  // Validate fields
  if (!email) {
    throw new Error('email is required')
  }

  if (!isEmail(email)) {
    throw new Error('email is not valid')
  }

  if (!password) {
    throw new Error('password is required')
  }

  if (authType === 'register' && !confirmPassword) {
    throw new Error('both password fields are required')
  }

  if (authType === 'register' && password !== confirmPassword) {
    throw new Error('password fields must match')
  }

  if (password.length < 8) {
    throw new Error('password must be 8 or more characters')
  }

  // Register or sign-in the user
  try {
    if (authType === 'register') {
      await authSignUp(email, password)
    }

    if (authType === 'signIn') {
      await authLogIn(email, password)
    }
  } catch (error) {
    // Auth0 errors are hard to parse.  This logic tries to handle that.
    let errorMessage

    if (error.message && typeof error.message === 'string') {
      errorMessage = error.message
    } else if (error.description && typeof error.description === 'string') {
      errorMessage = error.description
    } else if (
      error.description &&
      typeof error.description === 'object' &&
      error.description.rules
    ) {
      error.description.rules.forEach((rule) => {
        if (rule.code === 'containsAtLeast' || rule.code === 'specialCharacters') {
          errorMessage =
            'Password too weak.  It must contain a lowercase letter, uppercase letter, number and special character'
        }
      })
    } else {
      errorMessage = 'Sorry, an unknown error occurred.  Please try again later.'
    }

    throw new Error(errorMessage)
  }

  // After registration, try to log the user in
  if (authType === 'register') {
    try {
      await authLogIn(email, password)
    } catch (error) {
      let errorMessage

      if (error.message && typeof error.message === 'string') {
        errorMessage = error.message
      } else if (error.description && typeof error.description === 'string') {
        errorMessage = error.description
      } else {
        errorMessage = 'Sorry, an unknown error occurred.  Please try again later.'
      }

      throw new Error(errorMessage)
    }
  }
}

/**
 * Reset password via Auth0
 *
 * @param {*} email
 */
export const resetPassword = (email) =>
  new Promise((resolve, reject) =>
    auth0.changePassword(
      {
        email,
        connection: 'Username-Password-Authentication',
      },
      (error, result) => {
        if (error) {
          return reject(error)
        }

        return resolve(result)
      }
    )
  )

/**
 * Get Auth0 Profile
 */
export const getAuth0Profile = async (accessToken) => {
  return new Promise((resolve, reject) => {
    auth0.client.userInfo(accessToken, (error, profile) => {
      if (error) {
        return reject(error)
      }
      return resolve(profile)
    })
  })
}

/**
 * Exchange an authorization code for tokens
 * @param {*} code An Auth0 authorization code
 * @param {*} redirectUri A redirect URI
 */
export const authCallback = async (code, redirectUri = null) => {
  let res

  try {
    res = await apiClient({
      method: 'post',
      url: '/core/tokens',
      data: {
        code,
        redirectUri,
      },
    })
  } catch (error) {}

  return res
}
